import React from 'react';
import { css } from '@emotion/core';

const Select = ({ label, value, name, handleChange, children }) => {
  const selectLabelStyles = css(css`
    display: block;
    margin-bottom: 8px;
  `);

  const selectStyles = css(css`
    position: relative;
    display: block;
    width: 100%;
    min-height: 48px;
    font-size: 20px;
    color: white;
    background-color: transparent;
    border: 1px solid white;
    padding: 0 8px;
    -webkit-appearance: none;
    & + svg {
      position: absolute;
      top: 58%;
      right: 16px;
    }
  `);

  return (
    <div>
      <label css={selectLabelStyles} htmlFor={label}>
        {label}
      </label>
      <select
        css={selectStyles}
        name={name}
        value={value}
        onChange={e => handleChange(e)}
        onBlur={e => handleChange(e)}
      >
        <option>Please Select</option>
        {children}
      </select>
      <SmallDown />
    </div>
  );
};

export default Select;

function SmallDown() {
  return (
    <svg
      height="16"
      width="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#ffffff" stroke="#ffffff" strokeWidth="1">
        <polyline
          fill="none"
          points="3.5,6.5 8,11 12.5,6.5 "
          stroke="#ffffff"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}
