import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import Image from 'gatsby-image';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import { renderContent, setColorMode } from '../utilities/functions';

import HeroText from '../components/hero-text';
import Form from '../components/form';
import Footer from '../components/footer';
import { HEADING_LARGE, H2, H3, P, BUTTON } from '../elements/typography';
import { Container } from '../elements/layouts';

const transformNumber = num => {
  return '0' + num;
};

const Insights = ({ data, location }) => {
  const { headlineNode, content, downloads } = data.insights;
  const { htmlAst } = headlineNode.childMarkdownRemark;
  const url =
    'https://highsnobiety.us5.list-manage.com/subscribe/post?u=e84729e503aed1d6eba4c2b6b&amp;id=953a94c4a2';

  useEffect(() => {
    setColorMode(location.pathname);
  }, [location.pathname]);

  const gridStyles = css(css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-bottom: 64px;
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 128px;
    }
  `);
  const columnStyles = css(css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid black;
    padding-top: 8px;
  `);
  const newsletterSection = css(css`
    background-color: black;
    color: white;
    min-height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding: 64px 0;
    @media (min-width: 1024px) {
      padding: 128px 0;
    }
  `);

  const newsletterContent = css(css`
    width: 100%;
    padding: 24px;
    @media (min-width: 768px) {
      padding: 32px;
    }
    @media (min-width: 1024px) {
      width: 50%;
      margin-left: auto;
      padding: 0 32px 0 0;
    }
    h2 {
      margin-bottom: 16px;
      color: white;
    }
    p {
      color: white;
      max-width: 600px;
      margin-bottom: 32px;
    }
  `);

  return (
    <>
      <HeroText headline={htmlAst} />
      <Container>
        {renderContent(content)}

        <section className="download-area">
          <div css={gridStyles}>
            {downloads &&
              downloads.map((item, index) => {
                return (
                  <React.Fragment key={item.id}>
                    <div css={columnStyles}>
                      <HEADING_LARGE css={{ marginBottom: '48px' }}>
                        {transformNumber(downloads.length - index)}
                      </HEADING_LARGE>
                      <H3 css={{ marginBottom: '16px' }}>{item.title}</H3>
                      <P css={{ maxWidth: '500px', marginBottom: '32px' }}>
                        {item.description}
                      </P>
                      <BUTTON href={item.link}>Get Whitepaper</BUTTON>
                    </div>
                    <div>
                      <Image fluid={item.image.fluid} alt={item.image.alt} />
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </section>
        <section css={newsletterSection}>
          <div css={newsletterContent}>
            <H2>SignUp</H2>
            <P>
              Curated by our team of industry experts, inspired by our community
              of early adopters. Highsnobiety's Insights Newsletter delivers
              thought-provoking commentary and cultural insights straight to
              your inbox.
            </P>
            <div>
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                  <Form
                    status={status}
                    message={message}
                    subscribe={subscribe}
                  />
                )}
              />
            </div>
          </div>
        </section>
      </Container>
      <Footer pathname={location.pathname} />
    </>
  );
};

export default Insights;

export const query = graphql`
  query InsightsQuery {
    insights: datoCmsInsight {
      id
      title
      headlineNode {
        childMarkdownRemark {
          htmlAst
        }
      }
      content {
        ... on DatoCmsSingleImage {
          id
          alignment
          image {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsTextContent {
          id
          alignment
          width
          height
          textNode {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
      }
      downloads {
        id
        title
        description
        link
        image {
          alt
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`;
