import React, { useState } from 'react';
import { css } from '@emotion/core';

import FormMessage from './form-message';
import Input from './form-input';
import Select from './form-select';
import FORM_DATA from './DATA';
import { useEffect } from 'react';

const Form = ({ status, message, subscribe }) => {
  const [form, setForm] = useState(FORM_DATA);
  const [readyForSubmit, setIsReadyForSubmit] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: { ...form[name], value: value } });
  };
  const handleSubmit = e => {
    e.preventDefault();

    const formdata = {
      EMAIL: form.email.value,
      FNAME: form.fullName.value,
      JOBTITLE: form.jobTitle.value,
      MMERGE4: form.jobPosition.value,
      COMPANY: form.company.value,
      MMERGE5: form.industry.value,
    };
    subscribe(formdata);
  };
  useEffect(() => {
    const inputArr = Object.keys(form).map(el => {
      return form[el].value;
    });
    const checkInputState = el => {
      return el !== '' && el !== 'Please Select';
    };

    setIsReadyForSubmit(inputArr.every(checkInputState));
  }, [form]);

  const formStyles = css(css`
    display: flex;
    flex-direction: column;
    max-width: 600px;

    div {
      margin-bottom: 40px;
      text-transform: uppercase;
      position: relative;
    }
  `);
  const submitStyles = css(css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 48px;
    font-size: 18px;
    line-height: 1;
    color: black;
    text-transform: uppercase;
    background-color: white;
    border: none;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
    &:hover {
      background-color: transparent;
      border: 1px solid white;
      color: white;
    }
  `);

  return (
    <div>
      <form onSubmit={e => handleSubmit(e)} css={formStyles} autoComplete="off">
        {Object.keys(form).map((input, index) => {
          if (form[input].type === 'input') {
            return (
              <Input
                value={form[input].value}
                name={input}
                handleChange={handleChange}
                label={form[input].label}
                key={index}
              />
            );
          }
          if (form[input].type === 'select') {
            return (
              <Select
                key={index}
                label={form[input].label}
                name={input}
                value={form[input].value}
                handleChange={handleChange}
              >
                {form[input].options.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            );
          }
          return null;
        })}
        <button css={submitStyles} type="submit" disabled={!readyForSubmit}>
          Subscribe
        </button>
        {status !== null && <FormMessage status={status} message={message} />}
      </form>
    </div>
  );
};

export default Form;
