const FORM_DATA = {
  email: {
    type: 'input',
    label: 'email',
    placeholder: 'your email...',
    value: '',
  },
  fullName: {
    type: 'input',
    label: 'Full Name',
    placeholder: 'Full Name',
    value: '',
  },
  company: {
    type: 'input',
    label: 'Company',
    placeholder: 'Company Name',
    value: '',
  },
  jobTitle: {
    type: 'input',
    label: 'Job Title',
    placeholder: 'Your Job Title',
    value: '',
  },
  jobPosition: {
    type: 'select',
    label: 'industry',
    options: [
      'C-Level Executive',
      'Manager',
      'Executive / Coordinator',
      'Assistant / Entry Level',
      'Student',
      'Entrepreneur / Sole Proprietor',
      'Other',
    ],
    value: '',
  },
  industry: {
    type: 'select',
    label: 'industry',
    options: [
      'Advertising & Marketing',
      'Apparel & Fashion',
      'Automotive',
      'Beauty, Cosmetics & Perfumes',
      'Consulting & Business Services',
      'Consumer Electronics',
      'Digital & Ecommerce',
      'Education',
      'Financial Services',
      'FMCG',
      'Food & Beverages',
      'Government',
      'Health, Wellness & Fitness',
      'Industrial Goods',
      'IT',
      'Luxury Goods & Services (Non-Apparel & Fashion)',
      'Media and Entertainment',
      'Non-profit',
      'Research',
      'Retail',
      'Travel & Leisure',
      'Watches & Jewellry',
      'Other',
    ],
    value: '',
  },
};

export default FORM_DATA;
