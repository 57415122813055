import React from 'react';
import { css } from '@emotion/core';

const Input = ({ handleChange, value, label, name }) => {
  const labelStyles = css(css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    transition: top 300ms ease;
    pointer-events: none;
  `);
  const inputStyles = css(css`
    display: block;
    width: 100%;
    min-height: 48px;
    font-size: 20px;
    color: white;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;

    &.input--active {
      & + .form-label {
        transform: translateY(-80%);
        top: 0;
      }
    }
    &:focus {
      outline: none;
      & + .form-label {
        transform: translateY(-80%);
        top: 0;
      }
    }
    &-internal-autofill-selected {
      background: none;
    }
  `);

  return (
    <div>
      <input
        css={inputStyles}
        className={value !== '' ? 'input--active' : null}
        name={name}
        type="text"
        value={value}
        onChange={e => handleChange(e)}
        autoComplete="off"
      />
      <label className="form-label" css={labelStyles} htmlFor={label}>
        {label}
      </label>
    </div>
  );
};

export default Input;
